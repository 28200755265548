code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App, .col {
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.video-container {
  overflow: hidden;
  height: 100vh;
}
.bg-video {
  height: auto;
  overflow: hidden;
}

.blocks {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.33);
}

.display-1, .display-2, .display-3, .display-4 {
  /* font-family: 'Menlo', 'Monaco', 'Consolas', monospace; */
  font-family: 'Gotham', 'Open Sans', sans-serif;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.66);
}


@media (max-width: 575px) {
  .mbc-logo {
    max-height: 128px;
    margin-bottom: 3em;
  }
  .display-1 {
    font-size: 5em;
    font-weight: 500;
    line-height: 1.5;
  }
  .display-1 small {
    display: block;
    font-size: 55%;
    font-weight: 200;
  }
  .display-4 {
    font-size: 2em;
    margin-top: 2em;
    line-height: 1.5;
  }
}
@media (min-width: 576px) {
  .mbc-logo {
    max-height: 144px;
    margin-bottom: 3em;
  }
  .display-1 {
    font-size: 9em;
    font-weight: 500;
  }
  .display-1 small {
    font-size: 66.6666%;
    font-weight: 300;
  }
  .display-4 {
    margin-top: 1em;
  }
}
@media (min-width: 768px) {}
@media (min-width: 992px) {}
@media (min-width: 1200px) {}
